import React, { useState } from 'react'
import { Image } from 'primereact/image'
import { ProgressSpinner } from 'primereact/progressspinner'
import trcss from "../../styles/Telerecovery.module.css"

export default function ImageWithLoader({ src, width, height, aKey }) {
    const [loading, setLoading] = useState(true)

    const handleImageLoad = () => {
        setLoading(false)
    }

    return (
        <div key={aKey}>
            {loading && (
                <div className={trcss.loader_container}>
                    <ProgressSpinner
                        style={{ width: "30px", height: "30px" }}
                        strokeWidth="4"
                        animationDuration=".5s"
                    />
                </div>
            )}
            <img
                src={src}
                alt="Image"
                onLoad={handleImageLoad}
                onError={handleImageLoad}
                style={{ display: 'none' }}
            />
            <Image
                src={src}
                alt="media-image"
                width={width}
                height={height}
                preview
                className={`${trcss.mms} ${loading ? trcss.hidden : ''}`}
            />
        </div>
    )
}